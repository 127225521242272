import cn from "classnames"
import { Formik } from "formik"
import { find as _find, isArray as _isArray } from "lodash"
import React, { Component } from 'react'
import Button from '../components/button'
import Input from '../components/input'
import PageContents from '../components/pageContents'
import Select from '../components/select'
import SEO from "../components/seo"
import Title from '../components/title'
import NewLayout from '../layout/newLayout'
import { sendContact } from "../services/contact"
import styles from '../styles/scss/pages/industrialForm.module.scss'

export default class Index extends Component {

	state = {
		showModal: false,
		submitted: false,
		selectedIndustry: null,
	};

	industries = [{
		value: 'food-beverage',
		text: 'Food & Beverage'
	}, {
		value: 'oil-gas-chemical',
		text: 'Oil / Gas / Chemical'
	}, {
		value: 'mining-aggregate',
		text: 'Mining / Aggregate'
	}, {
		value: 'forest-paper',
		text: 'Forest / Paper'
	}, {
		value: 'power-utilities',
		text: 'Power & Utilities'
	}, {
		value: 'pharmaceutical',
		text: 'Pharmaceutical'
	}, {
		value: 'government',
		text: 'Government'
	}, {
		value: 'transportation-vehicles',
		text: 'Transportation / Vehicles'
	}, {
		value: 'warehouse-logistics',
		text: 'Warehouse & Logistics'
	}, {
		value: 'metal-metal-fabrication',
		text: 'Metal / Metal Fabrication'
	}, {
		value: 'commercial-construction',
		text: 'Commercial/Construction'
	}, {
		value: 'other',
		text: 'OTHER'
	}]

	render() {
		const pg = this.props.pageContext.page
		const attached = pg.attachments.filter((att) => {
			return att.slug === 'thank-you-contact'
		})
		var thankyou = null
		if (attached.length > 0) {
			thankyou = attached[0]
		} else {
			throw new Error("thank-you-contact is not attached")
		}

		return (
			<NewLayout>
				{pg.seo.title && <h1 style={{ display: 'none' }}>{pg.seo.title}</h1>}
				<SEO page={pg} />
				<div className='d-flex flex-column flex-fill'>
					<div className={styles.container}>
						<>
							<div className={cn(styles.formItems, "row")}>
								<div className={styles.formContainer}>
									{
										this.state.submitted ?
										<PageContents content={thankyou.content} />
										:
										<>
											<PageContents content={pg.content} />
											<div className={styles.info}>
												<h3 className={styles.infoTitle}>
													personal information
												</h3>
												<span className={styles.infoDescription}>
													* Required Fields
												</span>
											</div>
											<Formik
												initialValues={{
													first_name: "",
													last_name: "",
													job_title: "",
													company: "",
													phone: "",
													email: "",
													address: "",
													zip_code: "",
													industry: "",
													question: "",
												}}
												validate={values => {
													var errors = []
													//
													if (values.first_name === "") {
														errors.push({ field: "first_name", message: "First Name is required" })
													}
													//
													if (values.last_name === "") {
														errors.push({ field: "last_name", message: "Last Name is required" })
													}
													//
													if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
														errors.push({ field: "email", message: "Invalid email address" })
													}

													return errors
												}}

												onSubmit={(values, { setSubmitting }) => {

													this.setState({
														showModal: true
													})
													sendContact(values, 'technical-specialist').then(() => {
														setSubmitting(false)
														this.setState({
															submitted: true,
															showModal: false
														}, () => {
														})
													}).catch((err) => {
														console.log('ERROR!')
														console.log(err)
														setSubmitting(false)
														this.setState({
															showModal: false
														})
													})
												}}
											>
												{(props) => (
													<form className={styles.form} onSubmit={props.handleSubmit}>
														<div>
															<div className={styles.row}>
																<Input
																	name="first_name"
																	title="First Name*"
																	className={styles.input}
																	onChange={props.handleChange}
																	onBlur={props.handleBlur}
																	value={props.values.first_name}
																/>
																<Input
																	name="last_name"
																	title="Last Name*"
																	className={styles.input}
																	onChange={props.handleChange}
																	onBlur={props.handleBlur}
																	value={props.values.last_name}
																/>
															</div>
															<div className={styles.row}>
																<Input
																	name="job_title"
																	title="Job Title"
																	className={styles.input}
																	onChange={props.handleChange}
																	onBlur={props.handleBlur}
																	value={props.values.job_title}
																/>
																<Input
																	name="company"
																	title="Company"
																	className={styles.input}
																	onChange={props.handleChange}
																	onBlur={props.handleBlur}
																	value={props.values.company}
																/>
															</div>
															<div className={styles.row}>
																<Input
																	name="phone"
																	title="Phone"
																	phone="true"
																	className={styles.input}
																	onChange={props.handleChange}
																	onBlur={props.handleBlur}
																	value={props.values.phone}
																/>
																<Input
																	name="email"
																	title="Email"
																	className={styles.input}
																	onChange={props.handleChange}
																	onBlur={props.handleBlur}
																	value={props.values.email}
																/>
															</div>
															<div className={styles.row}>
																<Input
																	name="address"
																	title='Address'
																	className={styles.input}
																	onChange={props.handleChange}
																	onBlur={props.handleBlur}
																	value={props.values.address}
																/>
																<div className={styles.newRow}>
																	<Input
																		name="zip_code"
																		title='Zip Code'
																		className={styles.input}
																		onChange={props.handleChange}
																		onBlur={props.handleBlur}
																		value={props.values.zip_code}
																		style={{ width: '30%' }} 
																	/>
																	<div className={styles.industry}>
																		<Select
																			title="Industry"
																			titleClassName={styles.selectTitleNew}
																			noMargin
																			className={styles.selectNew}
																			placeholder='Select An Industry'
																			values={this.industries}
																			onBlur={props.handleBlur}
																			value={props.values.industry}
																			onChange={(e) => {
																				this.setState({
																					selectedIndustry: _find(this.industries, { value: e.value }),
																				}, () => {
																					props.setFieldValue('industry', e.value)
																				})
																			}}
																		/>
																	</div>
																</div>																	
															</div>
															<div className={styles.row}>
																<Input
																	name="question"
																	title="What is your question for our Technical Specialist?"
																	className={styles.textarea}
																	onChange={props.handleChange}
																	onBlur={props.handleBlur}
																	value={props.values.question}
																/>
															</div>
															<div className={styles.feedback}>
																<ul>
																	{_isArray(props.errors) && props.errors.filter((f) => { return props.touched[f.field] }).map((e) => {
																		return <li key={e.field}>{e.message}</li>
																	})}
																</ul>
															</div>
															<div className={styles.row}>
																<Button
																	text="Submit"
																	newBlue
																	submit
																	className={styles.input}
																/>
															</div>
														</div>															
													</form>
												)}
											</Formik>
										</>
									}
								</div>
							</div>
						</>
					</div>
				</div>
				{this.state.showModal &&
					<div className={cn(styles.customModal)}>
						<div className={cn(styles.modalContent)}>
							<div>
								<i className={cn('fa', 'fa-clock-o')}></i>
							</div>
							<div>
								<Title yellow>Please wait...</Title>
							</div>
						</div>
					</div>
				}
			</NewLayout>
		);
	}
}